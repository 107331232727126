import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "el-text__primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvoiceHeader = _resolveComponent("InvoiceHeader")!
  const _component_InvoiceDetails = _resolveComponent("InvoiceDetails")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_InvoiceCustomerContacts = _resolveComponent("InvoiceCustomerContacts")!
  const _component_InvoiceSentDetails = _resolveComponent("InvoiceSentDetails")!
  const _component_InvoiceCustomerNotes = _resolveComponent("InvoiceCustomerNotes")!
  const _component_InvoicePromiseToPayDetails = _resolveComponent("InvoicePromiseToPayDetails")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_InvoiceStatus = _resolveComponent("InvoiceStatus")!
  const _component_InvoiceNotes = _resolveComponent("InvoiceNotes")!
  const _component_InvoiceDocuments = _resolveComponent("InvoiceDocuments")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_DashboardLayout = _resolveComponent("DashboardLayout")!

  return (_openBlock(), _createBlock(_component_DashboardLayout, null, {
    header: _withCtx(() => [
      _createElementVNode("h1", null, [
        _createElementVNode("strong", _hoisted_1, " Invoice " + _toDisplayString(_ctx.invoice.invoice_no) + " - Detail Here " + _toDisplayString(_ctx.invoice.invoice_id), 1)
      ])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_el_space, {
        direction: "vertical",
        style: {"display":"flex"},
        fill: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InvoiceHeader, {
            account: _ctx.invoice.customer_name,
            "customer-no": _ctx.invoice.customer_no,
            contact: _ctx.invoice.contact,
            "customer-address": _ctx.invoice.customer_address,
            "salesperson-i-d": _ctx.invoice.salesperson_id
          }, null, 8, ["account", "customer-no", "contact", "customer-address", "salesperson-i-d"]),
          _createVNode(_component_el_row, { gutter: "10" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 16,
                sm: 16,
                md: 16,
                lg: 16,
                xl: 16
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_collapse, {
                    modelValue: _ctx.activeCollapseItem,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeCollapseItem) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_collapse_item, {
                        title: "Details",
                        name: "details"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InvoiceDetails, {
                            account: _ctx.invoice.customer_name,
                            "payment-terms": _ctx.invoice.payment_terms,
                            "customer-no": _ctx.invoice.customer_no,
                            "deposit-required": _ctx.invoice.deposit_required,
                            "invoice-no": _ctx.invoice.invoice_no,
                            "invoice-id": _ctx.invoice.invoice_id,
                            "net-days-from-invoice-date": _ctx.invoice.net_days_from_invoice_date,
                            "invoice-date": _ctx.invoice.invoice_date,
                            "reason-for-delinquency": _ctx.invoice.reason_for_delinquency,
                            "invoice-amount": _ctx.invoice.invoice_amount,
                            "reason-for-bad-debt-write-off": _ctx.invoice.reason_for_bad_debt_write_off,
                            "third-party-collector": _ctx.invoice.thirdpartycollector,
                            "balance-due": _ctx.invoice.balance_due,
                            "collector-code": _ctx.invoice.collector_code,
                            "hb-order-no": _ctx.invoice.order_no,
                            "hb-customer-po-no": _ctx.invoice.customer_po,
                            "customer-terms-code": _ctx.invoice.customer_terms_code,
                            "customer-terms-desc": _ctx.invoice.customer_terms_desc
                          }, null, 8, ["account", "payment-terms", "customer-no", "deposit-required", "invoice-no", "invoice-id", "net-days-from-invoice-date", "invoice-date", "reason-for-delinquency", "invoice-amount", "reason-for-bad-debt-write-off", "third-party-collector", "balance-due", "collector-code", "hb-order-no", "hb-customer-po-no", "customer-terms-code", "customer-terms-desc"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        title: "Customer Contacts",
                        name: "customerContacts"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InvoiceCustomerContacts, {
                            "customer-no": _ctx.invoice.customer_no
                          }, null, 8, ["customer-no"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        title: "Invoice Sent Details",
                        name: "invoiceSentDetails"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InvoiceSentDetails, {
                            "invoice-sent": _ctx.invoice.invoices_sent,
                            "invoice-sent-date": _ctx.invoice.invoices_sent_date,
                            "invoice-sent-method": _ctx.invoice.invoice_sent_method
                          }, null, 8, ["invoice-sent", "invoice-sent-date", "invoice-sent-method"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        title: "Customer Notes",
                        name: "customerNotes"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InvoiceCustomerNotes, {
                            "customer-no": _ctx.invoice.customer_no
                          }, null, 8, ["customer-no"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        title: "Promise to Pay Details",
                        name: "promiseToPayDetails"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InvoicePromiseToPayDetails, {
                            "promise-to-pay": _ctx.invoice.promise_to_pay,
                            "promise-to-pay-initiated-date": _ctx.invoice.promise_to_pay_initiated_date,
                            "promise-to-pay-amount": _ctx.invoice.promise_to_pay_amount,
                            "promise-to-pay-due-date": _ctx.invoice.promise_to_pay_due_date,
                            "promise-to-pay-check-number": _ctx.invoice.promise_to_pay_check_number
                          }, null, 8, ["promise-to-pay", "promise-to-pay-initiated-date", "promise-to-pay-amount", "promise-to-pay-due-date", "promise-to-pay-check-number"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                xs: 8,
                sm: 8,
                md: 8,
                lg: 8,
                xl: 8
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_space, {
                    direction: "vertical",
                    style: {"display":"flex"},
                    fill: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InvoiceStatus, {
                        "status-image-url": _ctx.invoice.status_image_url,
                        "invoice-status-indicator": _ctx.invoice.invoice_status_indicator
                      }, null, 8, ["status-image-url", "invoice-status-indicator"]),
                      _createVNode(_component_InvoiceNotes, {
                        notes: _ctx.invoice.notes
                      }, null, 8, ["notes"]),
                      _createVNode(_component_InvoiceDocuments, {
                        "customer-no": _ctx.invoice.external_id
                      }, null, 8, ["customer-no"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}