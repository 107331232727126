
import { defineComponent, onMounted, ref } from 'vue';

import { useRoute } from 'vue-router';

import { ElLoading } from 'element-plus';

import qs from 'qs';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

import useInvoice from '@/uses/useInvoice';

import {
  InvoiceCustomerContacts,
  InvoiceCustomerNotes,
  InvoiceDetails,
  InvoiceDocuments,
  InvoiceHeader,
  InvoiceNotes,
  InvoicePromiseToPayDetails,
  InvoiceSentDetails,
  InvoiceStatus,
} from '@/components/Invoice';

import { IColumn } from '@/interfaces/View';

export default defineComponent({
  name: 'Invoice',

  components: {
    DashboardLayout,
    InvoiceCustomerContacts,
    InvoiceCustomerNotes,
    InvoiceDetails,
    InvoiceDocuments,
    InvoiceHeader,
    InvoiceNotes,
    InvoicePromiseToPayDetails,
    InvoiceSentDetails,
    InvoiceStatus,
  },

  setup() {
    const route = useRoute();

    const activeCollapseItem = ref<string[]>([
      'details',
      'customerNotes',
      'customerContacts',
      'invoiceSentDetails',
      'promiseToPayDetails',
    ]);

    const { fetchInvoice, invoice } = useInvoice();

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const params: {
          column: IColumn;
          gridName: string;
          viewName: string;
          dataSource: string;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } = qs.parse(route.query);

        await fetchInvoice({
          viewName: 'invoices_extended',
          gridName: 'invoices_grid',
          gridRequest: {
            page_size: 1,
            page_index: 0,
            orders: [],
            filters: [{
              id: 'invoice_id',
              type: params.column.type,
              index: params.column.index,
              visible: params.column.visible,
              field_id: 'invoice_id',
              operation: '=',
              data_source: params.dataSource,
              filter_text: route.params.invoiceId.toString(),
              header_text: params.column.header_text,
              default_filter: '',
            }],
            drillthrough: null,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      activeCollapseItem,
      invoice,
    };
  },

  watch: {
    invoice: {
      handler() {
        if (this.invoice.invoice_no) {
          document.title = `Invoice ${this.invoice.invoice_no} Detail`;
        }
      },
      deep: true,
      immediate: true,
    },
  },

});
